import { GetValidationsResponse, Site } from '@m/types/api';
import { apiFetch } from '@m/utils/http';
import { GateService, SiteGroupService } from 'apps/specialist/services';
import { createEffect, createEvent } from 'effector';

export const setUserEnabledSites = createEvent<Site[]>();

export const getSite = createEffect({
  handler: ({ siteId }: { siteId: number }) => apiFetch(`/site/${siteId}`),
});

export const getSiteOccupancy = createEffect({
  handler: ({ siteId }: { siteId: number }) =>
    apiFetch(`/site/${siteId}/occupancy`, { isPolling: true }),
});

export const updateSiteOccupancy = createEffect({
  handler: ({ siteId, count }: { siteId: number; count: number }) =>
    apiFetch(`/site/${siteId}/occupancy`, {
      method: 'POST',
      body: { count },
    }),
});

export const getSiteTipSettings = createEffect({
  handler: ({ siteId }: { siteId: number }) =>
    apiFetch(`/admin/site/${siteId}/tip-settings`, { method: 'GET' }),
});

export const getSiteValidations = createEffect({
  handler: ({ siteId }: { siteId: number }) =>
    apiFetch<GetValidationsResponse>(`/site/${siteId}/validations`),
});

export const getSiteGates = createEffect({
  handler: ({ siteId }: { siteId: number }) => GateService.listGatesAtSite({ siteId }),
});

export const fetchSiteRateSchedules = createEffect({
  handler: ({ siteId }: { siteId: number }) =>
    apiFetch(`/specialist/site/${siteId}/current-day-rate-schedule`, { method: 'GET' }),
});

export const getSites = createEffect({
  handler: () => apiFetch('/v2/visit/enforcement/user', { method: 'GET' }),
});

export const getLocationWithZones = createEffect({
  handler: ({ siteId }: { siteId: number }) => SiteGroupService.getLocationWithZones({ siteId }),
});
